exports.components = {
  "component---src-pages-eduskuntavaalit-ehdokaslistaus-[name]-js": () => import("./../../../src/pages/eduskuntavaalit/ehdokaslistaus/[name].js" /* webpackChunkName: "component---src-pages-eduskuntavaalit-ehdokaslistaus-[name]-js" */),
  "component---src-pages-eduskuntavaalit-ehdokaslistaus-index-js": () => import("./../../../src/pages/eduskuntavaalit/ehdokaslistaus/index.js" /* webpackChunkName: "component---src-pages-eduskuntavaalit-ehdokaslistaus-index-js" */),
  "component---src-pages-eduskuntavaalit-tiedonkeruulomake-index-backup-for-municipality-elections-js": () => import("./../../../src/pages/eduskuntavaalit/tiedonkeruulomake/index-backup-for-municipality-elections.js" /* webpackChunkName: "component---src-pages-eduskuntavaalit-tiedonkeruulomake-index-backup-for-municipality-elections-js" */),
  "component---src-pages-eduskuntavaalit-tiedonkeruulomake-index-js": () => import("./../../../src/pages/eduskuntavaalit/tiedonkeruulomake/index.js" /* webpackChunkName: "component---src-pages-eduskuntavaalit-tiedonkeruulomake-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kuntavaalit-ehdokaslistaus-[name]-js": () => import("./../../../src/pages/kuntavaalit/ehdokaslistaus/[name].js" /* webpackChunkName: "component---src-pages-kuntavaalit-ehdokaslistaus-[name]-js" */),
  "component---src-pages-kuntavaalit-ehdokaslistaus-index-js": () => import("./../../../src/pages/kuntavaalit/ehdokaslistaus/index.js" /* webpackChunkName: "component---src-pages-kuntavaalit-ehdokaslistaus-index-js" */),
  "component---src-pages-kuntavaalit-tiedonkeruulomake-index-js": () => import("./../../../src/pages/kuntavaalit/tiedonkeruulomake/index.js" /* webpackChunkName: "component---src-pages-kuntavaalit-tiedonkeruulomake-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-candidate-page-js": () => import("./../../../src/templates/CandidatePage.js" /* webpackChunkName: "component---src-templates-candidate-page-js" */)
}

